<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label>Mã danh mục hồ sơ<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="code"
              rules="required"
              :custom-messages="code"
            >
              <b-form-input
                v-model="dataInput.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Tên danh mục hồ sơ<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="name"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Mô tả</label>

            <b-form-textarea
              v-model="dataInput.description"
              rows="3"
              no-resize
            />
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetailIndexFile: {
      type: Object,
      default: _ => {},
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      code: {
        required: 'Mã danh mục hồ sơ là bắt buộc',
      },
      name: {
        required: 'Tên danh mục hồ sơ là bắt buộc',
      },
      dataInput: {
        code: '',
        name: '',
        description: '',
      },
    }
  },
  watch: {
    dataDetailIndexFile(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetailIndexFile.code,
          name: this.dataDetailIndexFile.name,
          description: this.dataDetailIndexFile.description,
        }
      }
    },
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        code: '',
        name: '',
        injuryType: '',
      }
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
